import React, { useState } from "react";
import AuthService from '../../../services/auth.js'
import { useAuth } from '../../../context/AuthProvider.js';
import { NavLink, Navigate, useNavigate, useLocation } from 'react-router-dom'

//bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


//formik
import { Field, useFormik, Formik } from "formik";
import * as Yup from "yup";

//validation
const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .required("Required"),
    password: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/(?=.*[0-9])/, "Password must contain a number.")
});


function LogInForm() {
    // const auth = useAuth();
    const [username, setUsername] = React.useState('');//pendiente
    const [password, setPassword] = React.useState('');
    const [message, setMessage] = useState(null);

    //naviagte
    const navigate = useNavigate();
    const location = useLocation();

    //log in 
    const auth = useAuth();

    //log in bd
    const onSubmit = async (values) => {
        const data = { email: values.email, password: values.password }
        try {
            const authLogin = await AuthService.login(data);
            //console.log(authLogin.data.statusCode)
            if (authLogin.status === 200 && authLogin.data.accessToken) {
                const accessToken = authLogin.data?.accessToken;
                const roles = authLogin.data?.roles;
                const role = roles.reduce(() => '')
                sessionStorage.setItem("auth", JSON.stringify({ email: data.email, roles: role, accessToken: accessToken, user: authLogin.data?.userId }));

                // localStorage.setItem("persist", JSON.stringify({ email: value.email, roles: role, accessToken: accessToken }));
                setMessage({ error: false, msg: "Login successfully ... redirecting ... " });
                setTimeout(() => {
                    auth.loginOnNew(values.email);
                    //veryfi direction to log in
                    if (!location.state?.from) {
                        navigate('/');
                    } else {
                        navigate(location.state.from);
                    }
                }, 2000);
            } 
            if (authLogin.status === 200 && authLogin.data.statusCode === 404) {
                setMessage({ error: true, msg: 'Email is wrong! try again' });
            } else if (authLogin.status === 200 && authLogin.data.statusCode === 401) {
                setMessage({ error: true, msg: 'password is wrong! try again'});
            }

        } catch (error) {
            setMessage({ error: true, msg: 'An error has occurred! try again'});
        }
    }

    //formik
    const formik = useFormik({
        initialValues: { email: "", password: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });


    return (
        <>
            {message ?
                <div className={`alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
                    <strong>{message.msg}</strong>
                </div>
                : null
            }
            <div className="p-5">
                <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">Welcome to CMS!</h1>
                </div>
                <form className="user" onSubmit={formik.handleSubmit} >
                    <div className="mb-3 form-group ">
                        <input type="email" className="form-control form-control-user" placeholder="Email" id="email" name='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                        {formik.errors.email && formik.touched.email && (
                            <div className="form-text login-create-help text-danger">{formik.errors.email}</div>
                        )}
                    </div>
                    <div className="mb-3 form-group ">
                        <input type="password" className="form-control form-control-user" placeholder="Password" id="password" name='password'
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                        {formik.errors.password && formik.touched.password && (
                            <div className="form-text login-create-help text-danger">{formik.errors.password}</div>
                        )}
                    </div>
                    <button disabled={!formik.isValid} className="btn btn-primary btn-user btn-block" type="submit">
                        <span className="text">Log In</span>
                    </button>
                </form>
                <hr />
                <div className="text-center">
                    <a className="small" href="forgot-password.html">Forgot Password?</a>
                </div>
                <div className="text-center">
                    <a className="small" href="register.html">Create an Account!</a>
                </div>
            </div>
        </>
    );
}
export { LogInForm };