import React, { useState, useEffect } from 'react';
import ShadeService from '../../../services/shades.service.js';
import ReactPaginate from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import { Link, useParams, useNavigate } from 'react-router-dom';

//auth
import { useAuth } from '../../../context/AuthProvider.js';

//bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


//pages
import { CreateStep } from './steps/createStep';
import { Steps } from './steps/steps';

//formik
import { Field, useFormik, Formik } from "formik";
import * as Yup from "yup";

//validation
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required"),
  subtitle: Yup.string()
    .required("Required"),
  description: Yup.string()
    .required("Required")
});


function EditProduct() {
  //validation auth
  const auth = useAuth();
  //alerts
  const [message, setMessage] = useState(null);

  const { product_id } = useParams();
  //get all products
  //declare var items
  const [name, setName] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [backgroundImageURL, setBackgroundImageURL] = useState('');
  const [baseImageURL, setBaseImageURL] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [steps, setSteps] = useState(false);
  const [messageText, setMessagetext] = useState('');


  //images
  const [UpImageUrl, setUpImageUrl] = useState(null);
  const [UpBackgroundImageUrl, setUpBackgroundImageUrl] = useState(null);
  const [UpBaseImageUrl, setUpBaseImageUrl] = useState(null);


  useEffect(() => {
    //localStorage.removeItem('localProductSelected');
    getProducts();
  }, []);


  //get info product by id
  const getProducts = async () => {
    try {
      const getProductDetail = await ShadeService.getProductDetail(product_id);
      //console.log(getProductDetail)
      if (getProductDetail.status === 200 && getProductDetail.data.id) {

        //formil set values form update product
        formik.setValues({
          name: getProductDetail.data.name ? getProductDetail.data.name : '',
          subtitle: getProductDetail.data.subtitle ? getProductDetail.data.subtitle : '',
          description: getProductDetail.data.description ? getProductDetail.data.description : ''
        });
        //set imges
        setImageURL(getProductDetail.data.imageURL)
        setBackgroundImageURL(getProductDetail.data.backgroundImageURL)
        setBaseImageURL(getProductDetail.data.baseImageURL)

      } else {
        //message error
        setMessagetext({ msg: 'Oops try again!' })
        setMessage({ error: true, msg: "An error has occurred." });
        handleShow()
      }

    } catch (error) {
      //message error
      setMessagetext({ msg: 'Oops try again!' })
      setMessage({ error: true, msg: "An error has occurred." });
      handleShow()
    }
  }

  //update  product
  const onSubmit = async (values) => {
    console.log(values)
    //e.preventDefault();
    const dataUpdate = {
      name: values.name,
      subtitle: values.subtitle,
      description: values.description
    };
    try {
      const updateProduct = await ShadeService.UpdateProduct(product_id, dataUpdate);
      console.log(updateProduct)
      if (updateProduct.status === 200 && updateProduct.data.statusCode === 200) {
        setMessage({ error: false, msg: "Update Product. " });
        setShow(true);

      } else {
        setMessagetext({ msg: 'Oops try again!' })
        setMessage({ error: true, msg: "An error has occurred." });
        handleShow()
      }

    } catch (error) {
      setMessagetext({ msg: 'Oops try again!' })
      setMessage({ error: true, msg: "An error has occurred." });
      handleShow()
    }
  }

  //formik
  const formik = useFormik({
    initialValues: { name: "", subtitle: "", description: "" },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  //back button
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }

  //letter uppercase
  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false) }
  const handleShow = () => setShow(true);


  //refres page
  function refreshPage() {
    window.location.reload(false);
  }

  //images

  //imageURL
  const handleFileUploadImageUrl = (event) => {
    const file = event.target.files[0];
    if (file && !/^(image\/jpeg|image\/png)$/.test(file.type)) {
      UpImageUrl('');
      setMessage({ error: true, msg: 'The file must be of type PNG or JPG' });
      return;
    }
    const maxSize = 1 * 1024 * 1024; // 5 MB
    if (file && file.size > maxSize) {
      UpImageUrl('');
      setMessage({ error: true, msg: `The file must be less than or equal to  ${maxSize / 1024 / 1024} MB` });
      return;
    }
    setUpImageUrl(event.target.files[0]);
    //handleSubmitImgUrl(file);
  }
  const handleSubmitImgUrl = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('imageurl', UpImageUrl);

    if (UpImageUrl) {
      console.log(formData)
      try {
        let bearer_Token = {
          'headers': {
            'Authorization': `Bearer ${auth.authToken()}`
          },
        };
        const uploadAvatar = await ShadeService.updateImgUrl(product_id, formData, bearer_Token);
        //console.log(uploadAvatar)
        if (uploadAvatar.status === 200 && uploadAvatar.data.statusCode === 200) {
          setMessage({ error: false, msg: ' image uploaded successfully' });
          getProducts();
        } else {
          setMessage({ error: true, msg: 'An error has occurred! try again' });
        }
      } catch (error) {
        setMessage({ error: true, msg: 'An error has occurred! try again' });
      }
    } else {
      setMessage({ error: true, msg: 'please select Image' });
    }
  }

  //background image url
  const handleFileUploadBackgroundImageUrl = (event) => {
    const file = event.target.files[0];
    if (file && !/^(image\/jpeg|image\/png)$/.test(file.type)) {
      setUpBackgroundImageUrl('');
      setMessage({ error: true, msg: 'The file must be of type PNG or JPG' });
      return;
    }
    const maxSize = 1 * 1024 * 1024; // 5 MB
    if (file && file.size > maxSize) {
      setUpBackgroundImageUrl('');
      setMessage({ error: true, msg: `The file must be less than or equal to  ${maxSize / 1024 / 1024} MB` });
      return;
    }
    setUpBackgroundImageUrl(event.target.files[0]);
    //handleSubmitImgUrl(file);
  }

  const handleSubmitBackgroundImgUrl = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('backgroundimageurl', UpBackgroundImageUrl);

    if (UpBackgroundImageUrl) {
      console.log(formData)
      try {
        let bearer_Token = {
          'headers': {
            'Authorization': `Bearer ${auth.authToken()}`
          },
        };
        const uploadAvatar = await ShadeService.updateBacgroundImgUrl(product_id, formData, bearer_Token);
        //console.log(uploadAvatar)
        if (uploadAvatar.status === 200 && uploadAvatar.data.statusCode === 200) {
          setMessage({ error: false, msg: ' image uploaded successfully' });
          getProducts();
        } else {
          setMessage({ error: true, msg: 'An error has occurred! try again' });
        }
      } catch (error) {
        setMessage({ error: true, msg: 'An error has occurred! try again' });
      }
    } else {
      setMessage({ error: true, msg: 'please select Image' });
    }
  }

    //base image url
    const handleFileUploadBaseImageUrl = (event) => {
      const file = event.target.files[0];
      if (file && !/^(image\/jpeg|image\/png)$/.test(file.type)) {
        setUpBaseImageUrl('');
        setMessage({ error: true, msg: 'The file must be of type PNG or JPG' });
        return;
      }
      const maxSize = 1 * 1024 * 1024; // 5 MB
      if (file && file.size > maxSize) {
        setUpBaseImageUrl('');
        setMessage({ error: true, msg: `The file must be less than or equal to  ${maxSize / 1024 / 1024} MB` });
        return;
      }
      setUpBaseImageUrl(event.target.files[0]);
      //handleSubmitImgUrl(file);
    }
  
    const handleSubmitBaseImgUrl = async (event) => {
      event.preventDefault();
      const formData = new FormData();
      formData.append('baseimageurl', UpBaseImageUrl);
  
      if (UpBaseImageUrl) {
        console.log(formData)
        try {
          let bearer_Token = {
            'headers': {
              'Authorization': `Bearer ${auth.authToken()}`
            },
          };
          const uploadBaseImage = await ShadeService.updateBaseImgUrl(product_id, formData, bearer_Token);
         // console.log(uploadBaseImage)
          if (uploadBaseImage.status === 200 && uploadBaseImage.data.statusCode === 200) {
            setMessage({ error: false, msg: ' image uploaded successfully' });
            getProducts();
          } else {
            setMessage({ error: true, msg: 'An error has occurred! try again' });
          }
        } catch (error) {
          setMessage({ error: true, msg: 'An error has occurred! try again' });
        }
      } else {
        setMessage({ error: true, msg: 'please select Image' });
      }
    }

  return (
    <>
      {message ?
        <div className={`alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
          <strong>{message.msg}</strong>
        </div>
        : null
      }
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">

          <button type="button" onClick={goBack} className="btn btn-outline-secondary  btn-sm mb-4">
            <i className="fa-solid fa-chevron-left"></i>Back
          </button>
          <div className="btn-group">
            {
              steps
                ? <Link to={`/products/steps/${product_id}`}>
                  <button type="button" className="btn btn-outline-success  btn-sm mb-4">
                    <i className="fa-regular fa-pen-to-square"></i> Update Steps
                  </button>
                </Link>
                : <Link to={`/products/steps/${product_id}`}>
                  <button type="button" className="btn btn-outline-success  btn-sm mb-4">
                    <i className="fa-solid fa-plus"></i> Create Steps
                  </button>
                </Link>
            }
          </div>
        </div>

        <h1 className="h3 mb-2 text-gray-800">Edit <span>{formik.values.name}</span></h1>
        <div className="row">
          <div className="col-xl-6 col-md-12 col-lg-7">
            <form className=" needs-validation" onSubmit={formik.handleSubmit}>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">Create Product</h6>
                </div>
                <div className="card-body">
                  <div className="mb-3 form-group ">
                    <label className="form-label">Name Product</label>
                    <input type="text" className="form-control" id="name" name='name'
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} />
                    {formik.errors.name && formik.touched.name && (
                      <div className="form-text login-create-help text-danger">{formik.errors.name}</div>
                    )}
                  </div>
                  <div className="mb-3 form-group ">
                    <label className="form-label">Sub Title</label>
                    <input type="text" className="form-control" id="subtitle" name='subtitle'
                      value={formik.values.subtitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} />
                    {formik.errors.subtitle && formik.touched.subtitle && (
                      <div className="form-text login-create-help text-danger">{formik.errors.subtitle}</div>
                    )}
                  </div>
                  <div className="mb-3 form-group ">
                    <label className="form-label">Description</label>
                    <textarea className="form-control" placeholder="Description" id="description" name='description'
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} />
                    {formik.errors.description && formik.touched.description && (
                      <div className="form-text login-create-help text-danger">{formik.errors.description}</div>
                    )}
                  </div>
                </div>

              </div>
              <button disabled={!formik.isValid} className="btn btn-succes btn-icon-split btn-sm btn btn-primary" type="submit">
                <span className="icon text-white-50">
                  <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text">Update Product</span>
              </button>
            </form>
          </div>

          <div className="col-xl-6 col-md-12 col-lg-7">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Images</h6>
              </div>
              <div className="card-body">

                <div className="text-center mb-4">
                  <h6>Img Url</h6>
                  <img src={imageURL} className="w-25 avatar img-circle img-thumbnail" alt="avatar" />
                  <form onSubmit={handleSubmitImgUrl} >
                    <div className='row'>
                      <div className='col-md-6 '>
                        <input name="file" className="form-control form-control-sm mt-3" type="file" onChange={handleFileUploadImageUrl} />
                      </div>
                      <div className='col-md-6'>
                        <button className=" w-50 btn btn-outline-primary mt-3 " type="submit">Upload</button>
                      </div>
                    </div>
                  </form>
                </div>

                <hr />
                <div className="text-center mb-4">
                  <h6>backgroundImageUR</h6>
                  <img src={backgroundImageURL} className="w-25 avatar img-circle img-thumbnail" alt="image" />
                  <form onSubmit={handleSubmitBackgroundImgUrl} >
                    <div className='row'>
                      <div className='col-md-6 '>
                        <input name="backgroundimageurl" className="form-control form-control-sm mt-3" type="file" onChange={handleFileUploadBackgroundImageUrl} />
                      </div>
                      <div className='col-md-6'>
                        <button className=" w-50 btn btn-outline-primary mt-3 " type="submit">Upload</button>
                      </div>
                    </div>
                  </form>
                </div>
                <hr />


                <div className="text-center mb-4">
                  <h6> baseImageURL</h6>
                  <img src={baseImageURL} className="w-25 avatar img-circle img-thumbnail" alt="avatar" />
                  <form onSubmit={handleSubmitBaseImgUrl} >
                    <div className='row'>
                      <div className='col-md-6 '>
                        <input name="baseimageurl" className="form-control form-control-sm mt-3" type="file" onChange={handleFileUploadBaseImageUrl} />
                      </div>
                      <div className='col-md-6'>
                        <button className=" w-50 btn btn-outline-primary mt-3 " type="submit">Upload</button>
                      </div>
                    </div>
                  </form>
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        {/*   <CreateStep /> */}
        <Steps />
      </div>

      <Modal show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        onClick={handleClose}>
        <Modal.Header >
          <Modal.Title>{messageText.msg} {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {message ?
            <div className={`alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
              <strong>{message.msg}</strong>
            </div>
            : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={getProducts}> Close </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export { EditProduct };
