import React from "react";

function TodoFooter() {
    return (

        
             
        // <!-- Footer -->
        <footer className="sticky-footer bg-white">
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright &copy; CharlieApps 2023</span>
                </div>
            </div>
        </footer>
        // <!-- End of Footer -->
    );

}

export { TodoFooter };
