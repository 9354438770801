import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//bootstrap
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
//bootstrap js 
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

//fontAwesome
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
//import '../react-bootstrap-sidebar-menu/dist/sidebar-menu.scss'

// <!-- Custom styles for this template-->
import './assets/css/sb-admin-2.css'

//import './assets/css/sb-admin-2.min.css';

//security
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
<App />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
