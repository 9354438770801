import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider.js'


function Unauthorized() {
    const auth = useAuth();
    const shoot = () => {
        auth.logout();
    }

    return (
        <>
            <div className="container-fluid">
                <div className="text-center">
                    <div className="error mx-auto" data-text="401">401</div>
                    <p className="lead text-gray-800 mb-5">401 Unauthorized</p>
                    <p className="text-gray-500 mb-0">You do not have permissions to perform the action.</p>
                    <Link to='/login' onClick={shoot} >&larr; Back Log In</Link>
                </div>

            </div>
        </>

    );
}
export { Unauthorized };
