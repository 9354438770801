import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import ShadeService from '../../../services/shades.service.js';
import { Link } from 'react-router-dom';

//auth
import { useAuth } from '../../../context/AuthProvider.js';

function ProductsAll() {

    //validation auth
    const auth = useAuth();

    //get all products
    const [shades, setShades] = useState([]);
    useEffect(() => {
        localStorage.removeItem('localProductSelected');
        getProducts();
    }, []);
    const getProducts = async () => {
        try {
            const getProducts = await ShadeService.getAll();
            //console.log(getProducts)
            if (getProducts.status === 200) {
                setShades(getProducts.data)
            } else {
                setMessagetext({ msg: 'An error has occurred! try again' })
            }
        } catch (error) {
            setMessagetext({ msg: 'An error has occurred! try again' })
        }
    }

    //modal delete product
    const [productId, setProductId] = useState(null);
    const [productName, setProductName] = useState(null);
    const [message, setMessage] = useState(null);
    const [messageText, setMessagetext] = useState('');

    const [show, setShow] = useState(false);
    const deleteButtonClose = () => {
        setProductId('');
        setProductName('');
        setMessagetext({ msg: '' })
        setShow(false)
    };
    const deleteButton = (e) => {
        const ide = e.currentTarget.id;
        const name = e.currentTarget.name;
        setProductId(ide);
        setProductName(name);
        setShow(true);
    };

    //delete product by id
    const deleteProduct = async () => {
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                },
            };
            const deleteProduct = await ShadeService.deleteProduct(productId, bearer_Token);
            console.log(deleteProduct)
            if (deleteProduct.status === 200 && deleteProduct.data.statusCode === 200) {
                getProducts();
                setMessage({ error: false, msg: "The product has been successfully removed " });
                setShow(false)
            } else if (deleteProduct.status === 200 && deleteProduct.data.statusCode === 404) {
                setMessagetext({ msg: 'product nor found! try again!' })
                setShow(true)
            } else {
                setMessagetext({ msg: 'An error has occurred! try again' })
                setShow(true)
            }
        } catch (error) {
            setMessagetext({ msg: 'An error has occurred! try again' })
            setShow(true)
        }
    }

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = shades.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(shades.length / itemsPerPage);
    const handleClick = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };
    const prevButtonDisabled = currentPage === 1;
    const nextButtonDisabled = currentPage === Math.ceil(shades.length / itemsPerPage);
    //console.log(prevButtonDisabled);
    //end pagination

    //search
    const [searchTerm, setSearchTerm] = useState('');

    const filteredProducts = currentItems.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <>
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Products</h1>
                            <div className="btn-group">
                                <Link to={'/products/create'}>  <Button variant="btn btn-outline-success btn-sm"> Add  <i className="fa-solid fa-plus "></i> </Button>
                                </Link>
                            </div>
                        </div>
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">All Products
                                </h6>
                                {message ?
                                    <div className={`alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
                                        <strong>{message.msg}</strong>
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="card-header py-3">
                                <form className="d-flex">
                                    <input className="form-control me-1" type="search" placeholder="Search by Name" value={searchTerm} onChange={handleSearch} />
                                </form>                                
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">                                
                                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Sub Title</th>
                                                <th>Description</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>Image</th>
                                                <th>Title</th>
                                                <th>Subtitle</th>
                                                <th>Description</th>

                                                <th>Edit</th>
                                            </tr>
                                        </tfoot>
                                        <>

                                        </>
                                        <tbody>
                                            {
                                                filteredProducts.map((product, index) => (
                                                    <tr key={product.id}>
                                                        <td><img src={product.imageURL} className="img-thumbnail" width="100" height="100" /></td>
                                                        <td>{product.name}</td>
                                                        <td>{product.subtitle}</td>
                                                        <td>{product.description}</td>
                                                        <td>
                                                            <div className="btn-toolbar" role="toolbar">
                                                                <div className="btn-group">
                                                                    {<OverlayTrigger overlay={
                                                                        <Tooltip >
                                                                            Edit Product
                                                                        </Tooltip>} >
                                                                        <Link to={`/products/${product.id}`}>
                                                                            <Button variant="btn btn-outline-info"> <i className="fa-solid fa-pencil"></i> </Button>
                                                                        </Link>
                                                                    </OverlayTrigger>}
                                                                    {<OverlayTrigger overlay={
                                                                        <Tooltip >
                                                                            Delete Product
                                                                        </Tooltip>} >
                                                                        <Link  >
                                                                            <Button variant="btn btn-outline-danger" id={product.id} name={product.name} onClick={deleteButton} >    <i className="fa-solid fa-trash-can"></i></Button>
                                                                        </Link>
                                                                    </OverlayTrigger>}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination">
                                            <li className={prevButtonDisabled ? 'disabled page-item' : 'page-item'} ><a onClick={handlePrevClick} className="page-link">Previous</a></li>
                                            {pageNumbers.map(number => (
                                                <li className="page-item" key={number}>
                                                    <a className="page-link" href="#" onClick={e => handleClick(e, number)}>
                                                        {number}
                                                    </a>
                                                </li>
                                            ))}
                                            <li className={nextButtonDisabled ? 'disabled page-item' : 'page-item'}><a onClick={handleNextClick} className="page-link">Next</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

            <Modal show={show} onHide={deleteButtonClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{messageText.msg}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" col-md-12 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                            Are you sure to delete</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{productName}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa-regular fa-trash-can fa-2x text-gray-300" ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={deleteButtonClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteProduct}>
                        Delete Product
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export { ProductsAll };
