import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, NavLink , Navigate, useNavigate, useLocation } from 'react-router-dom'
import { ProductsAll } from '../pages/products/Products.js'
import { EditProduct } from '../pages/products/EditProduct.js'
import { ProfilePage } from '../pages/profile/Profile.js'
import { ProfileNav } from './ProfileNav'
import { TodoFooter } from '../../components/layouts/Footer'

import { CreateProduct } from '../pages/products/createProduct.js'
import { Items } from '../pages/products/items/items.js'

//auth
import { useAuth } from '../../context/AuthProvider';

function TodoMenu() {

    //naviagte
    const navigate = useNavigate();
    const location = useLocation();

    const auth = useAuth();
    //

    useEffect(() => {
      // console.log(auth.authRol())
       if(auth.authRol()==='admin'){
       }else{
        auth.logout();
        navigate('/unauthorized');
       }
      
      }, []);
    
    const session = JSON.parse(localStorage.getItem('persist'));
    const routesMenu = [];
    routesMenu.push({
        id: '1',
        to: '/orders',
        title: 'orders',
        icon: 'fa-solid fa-basket-shopping',
        rol: 'admin',
    });
    routesMenu.push({
        id: '2',
        to: '/products',
        title: 'products',
        icon: 'fa-solid fa-shop',
        rol: 'admin',
    });
    routesMenu.push({
        id: '3',
        to: '/',
        title: 'data',
        icon: 'fa-solid fa-house',
        rol: 'admin',
    });
    return (
        <>
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="">
                    <div className="sidebar-brand-icon rotate-n-15">
                        <i className="fa-solid fa-layer-group"></i>
                    </div>
                    <div className="sidebar-brand-text mx-3"> Admin CMS</div>
                </a>

                <hr className="sidebar-divider" />
                <div className="sidebar-heading">
                    Dasboard
                </div>
                <li className="nav-item">
                    <NavLink className="nav-link" to={'/'} ><i className="fa-solid fa-gauge"></i>Dasboard</NavLink>
                </li>
                {routesMenu.map((element, i) => {
                        return (
                            <div key={i}>
                                <hr className="sidebar-divider" />
                                <div className="sidebar-heading">
                                </div>
                                <li className="nav-item" key={element.id}>
                                    <NavLink className="nav-link" to={element.to} ><i className={element.icon}></i>{element.title}</NavLink>
                                </li>
                            </div >
                        )
                })}
                <hr className="sidebar-divider d-none d-md-block" />
                <div className="sidebar-card d-none d-lg-flex">
                    <p className="text-center mb-2"><strong>Dasboard</strong>Admin </p>
                </div>
            </ul>
            <div id="content-wrapper" className="d-flex flex-column">
                <ProfileNav />
                <Routes>
                    <Route path='/products' element={<ProductsAll />}></Route>
                    <Route path='/products/:product_id' element={<EditProduct />}></Route>
                    <Route path='/products/create' element={<CreateProduct />}></Route>
                    <Route path='/profile' element={<ProfilePage />}></Route>
                    <Route path='/items/:step_id' element={<Items />}></Route>
                </Routes>
                <TodoFooter />
            </div>
        </>
    );

}



export { TodoMenu };
