import React, { useState } from 'react';
import ShadeService from '../../../services/shades.service.js';
import { useNavigate, Link, Navigate } from 'react-router-dom';

//bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

//auth
import { useAuth } from '../../../context/AuthProvider.js';

//formik
import { Field, useFormik, Formik } from "formik";
import * as Yup from "yup";

//validation
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required"),
    subtitle: Yup.string()
        .required("Required"),
    description: Yup.string()
        .required("Required")
});


function CreateProduct() {
    //validation auth
    const auth = useAuth();

    const [message, setMessage] = useState(null);
    const [idProduct, setIdProduct] = useState('');

    //back button
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    //letter uppercase
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    //Create
    const onSubmit = async (values) => {
        //  e.preventDefault();
        const dataCreate = {
            name: values.name,
            subtitle: values.subtitle,
            description: values.description,

        };
        // console.log(dataCreate)
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                },
            };
            const createProduct = await ShadeService.createProduct(dataCreate, bearer_Token);
            console.log(createProduct)
            if (createProduct.status === 200 && createProduct.data.statusCode === 201) {
                //create
                setMessage({ error: false, msg: "Create Product." });
                setIdProduct(createProduct.data.id)
                handleShow();
            } else if (createProduct.status === 200 && createProduct.data.statusCode === 400) {
                //error 400
                setMessage({ error: true, msg: "An error has occurred!" });
            } else {
                setMessage({ error: true, msg: "An error has occurred!" });
            }
        } catch (error) {
            setMessage({ error: true, msg: "An error has occurred!" });

        }
    }

    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => { }
    const handleShow = () => setShow(true);

    //formik
    const formik = useFormik({
        initialValues: { name: "", subtitle: "", description: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <button type="button" onClick={goBack} className="btn btn-outline-secondary  btn-sm mb-4"><i className="fa-solid fa-chevron-left"></i>Back</button>
                    <div className="btn-group">
                    </div>
                </div>
                <h1 className="h3 mb-0 text-gray-800">Create Product</h1>
                <form className=" needs-validation" onSubmit={formik.handleSubmit}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Create Product</h6>
                        </div>
                        <div className="card-body">
                            <div className="mb-3 form-group ">
                                <label className="form-label">Name Product</label>
                                <input type="text" className="form-control" id="name" name='name'
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                                {formik.errors.name && formik.touched.name && (
                                    <div className="form-text login-create-help text-danger">{formik.errors.name}</div>
                                )}
                            </div>
                            <div className="mb-3 form-group ">
                                <label className="form-label">Sub Title</label>
                                <input type="text" className="form-control" id="subtitle" name='subtitle'
                                    value={formik.values.subtitle}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                                {formik.errors.subtitle && formik.touched.subtitle && (
                                    <div className="form-text login-create-help text-danger">{formik.errors.subtitle}</div>
                                )}
                            </div>
                            <div className="mb-3 form-group ">
                                <label className="form-label">Description</label>
                                <textarea className="form-control" placeholder="Description" id="description" name='description'
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                                {formik.errors.description && formik.touched.description && (
                                    <div className="form-text login-create-help text-danger">{formik.errors.description}</div>
                                )}
                            </div>
                        </div>

                    </div>
                    <button disabled={!formik.isValid} className="btn btn-succes btn-icon-split btn-sm btn btn-primary" type="submit">
                        <span className="icon text-white-50">
                            <i className="fa-solid fa-plus"></i>
                        </span>
                        <span className="text">Save Product</span>
                    </button>
                </form>
            </div >

            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                onClick={handleClose}>
                <Modal.Header >
                    <Modal.Title>Create Product Susscesfull</Modal.Title>
                </Modal.Header>
                <Modal.Body onClick={handleClose}>
                    {message ?
                        <div className={`alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
                            <strong>{message.msg}</strong>
                        </div>
                        : null}
                </Modal.Body>
                <Modal.Footer>
                    <Link to={`/products/${idProduct}`} ><Button variant="secondary" onClick={handleClose}> Close </Button></Link>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export { CreateProduct };
