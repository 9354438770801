import React, { useContext } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';

const AuthContext = React.createContext();

const adminList = ['Irisval', 'RetaxMaster', 'freddier'];

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = React.useState({});

  
  //log in
  const loginOn = (username) => {
    setUser({ username });
    navigate('/');
  };

  //login
  const loginOnNew = (username) => {
    setUser({ username });
  };

  //veryfi token
  const authVerify = () => {
    if (JSON.parse(sessionStorage.getItem('auth'))) {
      const data = JSON.parse(sessionStorage.getItem('auth'));
      const token = data['accessToken'];
      if (token) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  //get token
  const authToken = () => {
    if (JSON.parse(sessionStorage.getItem('auth'))) {
      const data = JSON.parse(sessionStorage.getItem('auth'));
      const token = data['accessToken'];
      if (token) {
        return token;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  //get id
  //get id
  const authId = () => {
    if (JSON.parse(sessionStorage.getItem('auth'))) {
      const data = JSON.parse(sessionStorage.getItem('auth'));
      const user = data['user'];
      if (user) {
        return user;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  //get email
  const authEmail = () => {
    if (JSON.parse(sessionStorage.getItem('auth'))) {
      const data = JSON.parse(sessionStorage.getItem('auth'));
      const user = data['email'];
      if (user) {
        return user;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

   //get email
   const authRol = () => {
    if (JSON.parse(sessionStorage.getItem('auth'))) {
      const data = JSON.parse(sessionStorage.getItem('auth'));
      const user = data['roles'];
      if (user) {
        return user;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  //log out
  const logout = () => {
    setUser(null);
    sessionStorage.clear();
    navigate('/login');
  };

  const auth = { user, setUser, loginOn, loginOnNew, authVerify, authToken, authId, authEmail,authRol, logout };

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const auth = React.useContext(AuthContext);
  return auth;
}
function AuthRoute(props) {
  const auth = useAuth();

  if (!JSON.parse(sessionStorage.getItem('auth'))) {
    return <Navigate to="/login" />;
  }
  return props.children;
}
export {
  AuthProvider,
  useAuth,
  AuthRoute,
};
