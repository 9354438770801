import http from "../configs/http.config";

class ItemService {

    createItem(step_id, data, authorization) {
        return http.post(`/item/create/${step_id}`, data, authorization);
    }
    //get items by step id
    getItemById(step_id, authorization) {
        return http.get(`/item/get/${step_id}`, authorization);
    }

    //get items by step id
    UpdateItemById(item_id,data, authorization) {
        return http.patch(`/item/update/${item_id}`,data, authorization);
    }
    //get parent items
    getParentItem(item_name, authorization) {
        return http.get(`/item/parent_id/${item_name}`, authorization);
    }
     //get parent info
     getParentInfo(id, authorization) {
        return http.get(`/item/select_parent_id/${id}`, authorization);
        //entidad que aprobo eps que es farallones 1 no tiene coloporctologo me indicaron el cirujano con el que me habian indicado 
        //no opera ese tipo de cirugias.
    }
     //delete item
     deleteItem(id, authorization) {
        return http.get(`/item/delete_item/${id}`, authorization);
    }

}
export default new ItemService();