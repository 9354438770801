import http from "../configs/http.config";

class StepService {

    getStepById(step_id,authorization) {
        return http.get(`/steps/${step_id}`,authorization);
    }
    createStep(data,authorization) {
        return http.post(`/steps`,data,authorization);
    }
    updateStepbiId(step_id,data,authorization) {
        return http.patch(`/steps/${step_id}`,data,authorization);
    }
    deleteStepById(step_id,authorization) {
        return http.delete(`/steps/${step_id}`,authorization);
    }
}
export default new StepService();