import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import StepService from '../../../../services/step.service';

//alerts
import BasicExample from '../../alerts/step.alert';

//bootstrap
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

//auth
import { useAuth } from '../../../../context/AuthProvider';

//formik
import { Field, useFormik, Formik } from "formik";
import * as Yup from "yup";

//validation
const validationSchema = Yup.object().shape({
    step_number: Yup.number()
        .required("Required")
        .integer("must be an integer")
        .min(1, "Must be more than 1 characters"),
    step_title: Yup.string()
        .required("Required"),
    step_subtitle: Yup.string()
        .required("Required"),
    step_label: Yup.string()
        .required("Required"),
    step_note: Yup.string()
        .required("Required"),
    active: Yup.number()
        .required("Required"),
    step_get_data: Yup.number()
        .integer("must be an integer")
        .required("Required"),

    step_get_data_url: Yup.string(),
    step_execute_function: Yup.string(),
    step_enable_searcher: Yup.number()
        .required("Required"),
});


function Steps() {
    //validation auth
    const auth = useAuth();

    const navigate = useNavigate();
    const { product_id } = useParams();
    const [stepAll, setStepAll] = useState([]);

    //alerts
    const [message, setMessage] = useState(null);
    useEffect(() => {
        localStorage.removeItem('localProductSelected');
        getStepById();
    }, []);

    //get all steps
    const getStepById = async (e) => {
        const bearer_Token = {
            'headers': {
                'Authorization': `Bearer ${auth.authToken()}`
            }
        };
        //console.log(product_id)
        try {
            const getStepById = await StepService.getStepById(product_id, bearer_Token);
            //console.log(getStepById)
            if (getStepById.data.statusCode === 404) {
            } else if (getStepById.status === 200) {
                setStepAll(getStepById.data)
            }
        } catch (error) {
            // console.log(error)
            if (error.response.status === 401) {
                navigate('/unauthorized')
            } else if (error.response.status === 400) {
                setMessage({ error: true, msg: "An ocurred error in fields!. Try again." });
                handleShow()
            }
        }
    }

    //update step
    const onSubmitUpdate = async (values) => {
        const dataUpdate = {
            product_id: product_id,
            step_number: values.step_number,
            step_title: values.step_title,
            step_subtitle: values.step_subtitle,
            step_label: values.step_label,
            step_note: values.step_note,
            step_image_selector: "",
            step_get_data: values.step_get_data,
            step_get_data_url: values.step_get_data_url,
            step_execute_function: values.step_execute_function,
            step_enable_searcher: values.step_enable_searcher,
            active: values.active,
        }
        try {
            const bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            const updateStepById = await StepService.updateStepbiId(values.id, dataUpdate, bearer_Token);
            setMessage({ error: false, msg: "Update Step. " });
            getStepById();
            handleShow();
        } catch (error) {
            //console.log('updateStepById')
            //  console.log(error)
            if (error.response.status === 401) {
                navigate('/unauthorized')
            } else if (error.response.status === 400) {
                setMessage({ error: true, msg: "An ocurred error in fields!. Try again." });
                handleShow()
            }
            setMessage({ error: true, msg: "An ocurred error!. Try again." });
            handleShow()
        }
    }

    //delete step
    const deleteStepbyId = async (ide) => {
       // console.log('se ejecuta')
        try {
            const step_id = ide;
            const bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            const deleteStepbyId = await StepService.deleteStepById(step_id, bearer_Token);
            if (deleteStepbyId.data.statusCode === 200) {
                setMessage({ error: false, msg: "Stelp eliminated susscesful." });
                getStepById();
                handleShow()
            } else {
                setMessage({ error: true, msg: "Not Eliminated! Try again." });
                getStepById();
                handleShow();

            }
        } catch (error) {
            // console.log(error)
            if (error.response.status === 401) {
                navigate('/unauthorized')
            } else if (error.response.status === 400) {
                setMessage({ error: true, msg: "An ocurred error in fields!. Try again." });
                handleShow()
            }
            setMessage({ error: true, msg: "An ocurred error!. Try again." });
            handleShow()
        }
    }

    //create step
    const onSubmitCreate = async (values) => {
        const bearer_Token = {
            'headers': {
                'Authorization': `Bearer ${auth.authToken()}`
            }
        };
        const dataCreateStep = {
            product_id: product_id,
            step_number: values.step_number,
            step_title: values.step_title,
            step_subtitle: values.step_subtitle,
            step_label: values.step_label,
            step_note: values.step_note,
            step_image_selector: "",
            step_get_data: values.step_get_data,
            step_get_data_url: values.step_get_data_url,
            step_execute_function: values.step_execute_function,
            step_enable_searcher: values.step_enable_searcher,
            active: values.active,
        }
        try {
            const createStep = await StepService.createStep(dataCreateStep, bearer_Token);
            console.log(createStep)
            if (createStep.data.status === 201) {
                setMessage({ error: false, msg: "created step susscesfull." });
                setShsetButtonStepCreatnew(false);
                formikCreate.resetForm()
                getStepById();
                handleShow()

            } else if (createStep.data.output.statusCode === 406) {
                setMessage({ error: true, msg: "Step Number already exist! Try again." });
                handleShow()
            }
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({ error: true, msg: "An ocurred error in fields!. Try again." });
                handleShow()
            }
            setMessage({ error: true, msg: "An ocurred error!. Try again." });
            handleShow()
        }
    }

    //hide create step
    //const [buttonStepCreate, setButtonStepCreate] = useState('');
    const [buttonStepCreate, setShsetButtonStepCreatnew] = useState(false);
    const handleButtonStep = (event) => {
        setShsetButtonStepCreatnew(true);
    }
    const handleButtonStepFalse = (event) => {
        setShsetButtonStepCreatnew(false);
    }

    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //formik
    const formikCreate = useFormik({
        initialValues: {
            step_number: "", step_title: "", step_subtitle: "", step_label: "", step_note: "", step_get_data: "",
            step_get_data_url: "", step_execute_function: "", step_enable_searcher: "", active: ""
        },
        validateOnBlur: true,
        onSubmit: (values) => {
            onSubmitCreate(values);
        },
        validationSchema: validationSchema,
    });

    //Add items
     const onAddItems = async (ide) => {

     }

    return (
        <>
            <div className="container-fluid">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <div className="btn-group">
                        <button onClick={handleButtonStep} type="button" className="btn btn-outline-success  btn-sm mb-4">
                            <i className="fa-solid fa-plus"></i> Create Steps
                        </button>
                    </div>
                </div>
            </div>
            <div>
                {/*  create step  */}
                {
                    buttonStepCreate ?
                        <>
                            <form noValidate className="mb-4" onSubmit={formikCreate.handleSubmit} >
                                <div className="row">
                                    <div className="col-xl-12 col-lg-7">
                                        <div className="card shadow mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">Create Step</h6>
                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <div className="btn-group">
                                                        <button onClick={handleButtonStepFalse} type="button" className="btn btn-outline-danger  btn-sm mb-4">
                                                            <i className="fa-regular fa-circle-xmark"></i> Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className="mb-3 form-group ">
                                                            <label className="form-label">Step Number</label>
                                                            <input type="number" className="form-control" id="step_number" name='step_number'
                                                                value={formikCreate.values.step_number}
                                                                onChange={formikCreate.handleChange}
                                                                onBlur={formikCreate.handleBlur} />
                                                            {formikCreate.errors.step_number && formikCreate.touched.step_number && (
                                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.step_number}</div>
                                                            )}
                                                        </div>

                                                    </div>
                                                    <div className="col-9">
                                                        <div className="mb-3 form-group ">
                                                            <label className="form-label">Step Title</label>
                                                            <input type="text" className="form-control" id="step_title" name='step_title'
                                                                value={formikCreate.values.step_title}
                                                                onChange={formikCreate.handleChange}
                                                                onBlur={formikCreate.handleBlur} />
                                                            {formikCreate.errors.step_title && formikCreate.touched.step_title && (
                                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.step_title}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="mb-3 form-group ">
                                                            <label className="form-label">Step Subtile</label>
                                                            <input type="text" className="form-control" id="step_subtitle" name='step_subtitle'
                                                                value={formikCreate.values.step_subtitle}
                                                                onChange={formikCreate.handleChange}
                                                                onBlur={formikCreate.handleBlur} />
                                                            {formikCreate.errors.step_subtitle && formikCreate.touched.step_subtitle && (
                                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.step_subtitle}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3 form-group ">
                                                            <label className="form-label">Step Label</label>
                                                            <input type="text" className="form-control" id="step_label" name='step_label'
                                                                value={formikCreate.values.step_label}
                                                                onChange={formikCreate.handleChange}
                                                                onBlur={formikCreate.handleBlur} />
                                                            {formikCreate.errors.step_label && formikCreate.touched.step_label && (
                                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.step_label}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-3 form-group ">
                                                            <label className="form-label">Step Note</label>
                                                            <textarea className="form-control" id="step_note" name='step_note'
                                                                value={formikCreate.values.step_note}
                                                                onChange={formikCreate.handleChange}
                                                                onBlur={formikCreate.handleBlur} />
                                                            {formikCreate.errors.step_note && formikCreate.touched.step_note && (
                                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.step_note}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">Advance Settings</h6>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className='mb-3'>
                                                            <label className="form-label">Step Get Data</label>
                                                            <select className="form-select" id="step_get_data" name='step_get_data'
                                                                value={formikCreate.values.step_get_data}
                                                                onChange={formikCreate.handleChange}
                                                                onBlur={formikCreate.handleBlur}>
                                                                <option value="">Select an option</option>
                                                                <option value="1">YES</option>
                                                                <option value="0">NO</option>
                                                            </select>

                                                            {formikCreate.errors.step_get_data && formikCreate.touched.step_get_data && (
                                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.step_get_data}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3 form-group ">
                                                            <label className="form-label">Step Get Data Url</label>
                                                            <input type='text' className="form-control" id="step_get_data_url" name='step_get_data_url'
                                                                value={formikCreate.values.step_get_data_url}
                                                                onChange={formikCreate.handleChange}
                                                                onBlur={formikCreate.handleBlur} />
                                                            {formikCreate.errors.step_get_data_url && formikCreate.touched.step_get_data_url && (
                                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.step_get_data_url}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3 form-group ">
                                                            <label className="form-label">Step Execute Function</label>
                                                            <input type='text' className="form-control" id="step_execute_function" name='step_execute_function'
                                                                value={formikCreate.values.step_execute_function}
                                                                onChange={formikCreate.handleChange}
                                                                onBlur={formikCreate.handleBlur} />
                                                            {formikCreate.errors.step_execute_function && formikCreate.touched.step_execute_function && (
                                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.step_execute_function}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3 form-group ">
                                                            <label className="form-label">Step Enable Searcher</label>
                                                            <select className="form-select" id="step_enable_searcher" name='step_enable_searcher'
                                                                value={formikCreate.values.step_enable_searcher}
                                                                onChange={formikCreate.handleChange}
                                                                onBlur={formikCreate.handleBlur}>
                                                                <option value="">Select an option</option>
                                                                <option value="1">YES</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                            {formikCreate.errors.step_enable_searcher && formikCreate.touched.step_enable_searcher && (
                                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.step_enable_searcher}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3 form-group ">
                                                            <label className="form-label">Step Active</label>
                                                            <select className="form-select" id="active" name='active'
                                                                value={formikCreate.values.active}
                                                                onChange={formikCreate.handleChange}
                                                                onBlur={formikCreate.handleBlur}>
                                                                <option value="">Select an option</option>
                                                                <option value="1">YES</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                            {formikCreate.errors.active && formikCreate.touched.active && (
                                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.active}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-5 mb-4">
                                                        <div className="my-2"></div>
                                                        <button disabled={!formikCreate.isValid} className="btn btn-succes btn-icon-split btn-sm btn btn-primary" type="submit">
                                                            <span className="icon text-white-50">
                                                                <i className="fa-solid fa-plus"></i>
                                                            </span>
                                                            <span className="text">Save Step</span>
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </>
                        : <></>
                }
                {/* end create step */}

                {/* edit steps */}
                <div className="accordion" id="accordionExample">
                    {
                        stepAll.map((step, i) => {

                            return (
                                <Accordion key={step.id}>
                                    <Accordion.Item eventKey="0" >
                                        <Accordion.Header> {step.step_title}</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-7">
                                                    <div className="card shadow mb-4">
                                                        <div className="card-header py-3">
                                                            <h6 className="m-0 font-weight-bold text-primary">Edit Step</h6>
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <div className="btn-group">
                                                                <Link to={`/items/${step.id}`} type="button" onClick={() => onAddItems(step.id)} className="btn btn-outline-success  btn-sm mb-4">
                                                                        <i className="fa-solid fa-circle-plus"></i> Add Items
                                                                    </Link>
                                                                </div>

                                                                 <div className="btn-group">
                                                                    <button type="button" onClick={() => deleteStepbyId(step.id)} className="btn btn-outline-danger  btn-sm mb-4">
                                                                        <i className="fa-solid fa-trash-can"></i> Delete
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Formik
                                                            initialValues={{
                                                                id: step.id ? step.id : '',
                                                                step_number: step.step_number ? step.step_number : '', step_title: step.step_title ? step.step_title : '',
                                                                step_subtitle: step.step_subtitle ? step.step_subtitle : '', step_label: step.step_label ? step.step_label : '',
                                                                step_note: step.step_note ? step.step_note : '', step_get_data: step.step_get_data===1? step.step_get_data : '0',
                                                                step_get_data_url: step.step_get_data_url ? step.step_get_data_url : '', step_execute_function: step.step_execute_function ? step.step_execute_function : '',
                                                                step_enable_searcher: step.step_enable_searcher===1? step.step_enable_searcher : '0', active: step.active===1? step.active : '0'
                                                            }}
                                                            validateOnBlur={true}
                                                            onSubmit={(values) => {
                                                                onSubmitUpdate(values);
                                                            }}
                                                            validationSchema={validationSchema} >
                                                            {formikUpdate => (
                                                                <form noValidate className="mb-4" onSubmit={formikUpdate.handleSubmit} >

                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <input type="hidden" disabled className="form-control" id="id" name='id'
                                                                                value={formikUpdate.values.id} />
                                                                            <div className="col-3">
                                                                                <div className="mb-3 form-group ">
                                                                                    <label className="form-label">Step Number</label>
                                                                                    <input type="number" className="form-control" id="step_number" name='step_number'
                                                                                        value={formikUpdate.values.step_number}
                                                                                        onChange={formikUpdate.handleChange}
                                                                                        onBlur={formikUpdate.handleBlur} />
                                                                                    {formikUpdate.errors.step_number && formikUpdate.touched.step_number && (
                                                                                        <div className="form-text login-create-help text-danger">{formikUpdate.errors.step_number}</div>
                                                                                    )}
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-9">
                                                                                <div className="mb-3 form-group ">
                                                                                    <label className="form-label">Step Title</label>
                                                                                    <input type="text" className="form-control" id="step_title" name='step_title'
                                                                                        value={formikUpdate.values.step_title}
                                                                                        onChange={formikUpdate.handleChange}
                                                                                        onBlur={formikUpdate.handleBlur} />
                                                                                    {formikUpdate.errors.step_title && formikUpdate.touched.step_title && (
                                                                                        <div className="form-text login-create-help text-danger">{formikUpdate.errors.step_title}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="mb-3 form-group ">
                                                                                    <label className="form-label">Step Subtile</label>
                                                                                    <input type="text" className="form-control" id="step_subtitle" name='step_subtitle'
                                                                                        value={formikUpdate.values.step_subtitle}
                                                                                        onChange={formikUpdate.handleChange}
                                                                                        onBlur={formikUpdate.handleBlur} />
                                                                                    {formikUpdate.errors.step_subtitle && formikUpdate.touched.step_subtitle && (
                                                                                        <div className="form-text login-create-help text-danger">{formikUpdate.errors.step_subtitle}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="mb-3 form-group ">
                                                                                    <label className="form-label">Step Label</label>
                                                                                    <input type="text" className="form-control" id="step_label" name='step_label'
                                                                                        value={formikUpdate.values.step_label}
                                                                                        onChange={formikUpdate.handleChange}
                                                                                        onBlur={formikUpdate.handleBlur} />
                                                                                    {formikUpdate.errors.step_label && formikUpdate.touched.step_label && (
                                                                                        <div className="form-text login-create-help text-danger">{formikUpdate.errors.step_label}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div className="mb-3 form-group ">
                                                                                    <label className="form-label">Step Note</label>
                                                                                    <textarea className="form-control" id="step_note" name='step_note'
                                                                                        value={formikUpdate.values.step_note}
                                                                                        onChange={formikUpdate.handleChange}
                                                                                        onBlur={formikUpdate.handleBlur} />
                                                                                    {formikUpdate.errors.step_note && formikUpdate.touched.step_note && (
                                                                                        <div className="form-text login-create-help text-danger">{formikUpdate.errors.step_note}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="card-header py-3">
                                                                        <h6 className="m-0 font-weight-bold text-primary">Advance Settings</h6>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className='mb-3'>
                                                                                    <label className="form-label">Step Get Data</label>
                                                                                    <select className="form-select" id="step_get_data" name='step_get_data'
                                                                                        value={formikUpdate.values.step_get_data}
                                                                                        onChange={formikUpdate.handleChange}
                                                                                        onBlur={formikUpdate.handleBlur}>
                                                                                        <option value="">Select an option</option>
                                                                                        <option value="1">YES</option>
                                                                                        <option value="0">NO</option>
                                                                                    </select>

                                                                                    {formikUpdate.errors.step_get_data && formikUpdate.touched.step_get_data && (
                                                                                        <div className="form-text login-create-help text-danger">{formikUpdate.errors.step_get_data}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="mb-3 form-group ">
                                                                                    <label className="form-label">Step Get Data Url</label>
                                                                                    <input type='text' className="form-control" id="step_get_data_url" name='step_get_data_url'
                                                                                        value={formikUpdate.values.step_get_data_url}
                                                                                        onChange={formikUpdate.handleChange}
                                                                                        onBlur={formikUpdate.handleBlur} />
                                                                                    {formikUpdate.errors.step_get_data_url && formikUpdate.touched.step_get_data_url && (
                                                                                        <div className="form-text login-create-help text-danger">{formikUpdate.errors.step_get_data_url}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="mb-3 form-group ">
                                                                                    <label className="form-label">Step Execute Function</label>
                                                                                    <input type='text' className="form-control" id="step_execute_function" name='step_execute_function'
                                                                                        value={formikUpdate.values.step_execute_function}
                                                                                        onChange={formikUpdate.handleChange}
                                                                                        onBlur={formikUpdate.handleBlur} />
                                                                                    {formikUpdate.errors.step_execute_function && formikUpdate.touched.step_execute_function && (
                                                                                        <div className="form-text login-create-help text-danger">{formikUpdate.errors.step_execute_function}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="mb-3 form-group ">
                                                                                    <label className="form-label">Step Enable Searcher</label>
                                                                                    <select className="form-select" id="step_enable_searcher" name='step_enable_searcher'
                                                                                        value={formikUpdate.values.step_enable_searcher}
                                                                                        onChange={formikUpdate.handleChange}
                                                                                        onBlur={formikUpdate.handleBlur}>
                                                                                        <option value="">Select an option</option>
                                                                                        <option value="1">YES</option>
                                                                                        <option value="0">NO</option>
                                                                                    </select>
                                                                                    {formikUpdate.errors.step_enable_searcher && formikUpdate.touched.step_enable_searcher && (
                                                                                        <div className="form-text login-create-help text-danger">{formikUpdate.errors.step_enable_searcher}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="mb-3 form-group ">
                                                                                    <label className="form-label">Step Active</label>
                                                                                    <select className="form-select" id="active" name='active'
                                                                                        value={formikUpdate.values.active}
                                                                                        onChange={formikUpdate.handleChange}
                                                                                        onBlur={formikUpdate.handleBlur}>
                                                                                        <option value="">Select an option</option>
                                                                                        <option value="1">YES</option>
                                                                                        <option value="0">NO</option>
                                                                                    </select>
                                                                                    {formikUpdate.errors.active && formikUpdate.touched.active && (
                                                                                        <div className="form-text login-create-help text-danger">{formikUpdate.errors.active}</div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-12 col-lg-5 mb-4">
                                                                                <div className="my-2"></div>
                                                                                <button disabled={!formikUpdate.isValid} className="btn btn-succes btn-icon-split btn-sm btn btn-primary" type="submit">
                                                                                    <span className="icon text-white-50">
                                                                                        <i className="fa-solid fa-plus"></i>
                                                                                    </span>
                                                                                    <span className="text">Update Step</span>
                                                                                </button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            )}
                                                        </Formik>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )
                        })
                    }
                </div>
                {/*  end edit and delete  */}
            </div >

            {/* modal */}
            < Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} onClick={handleClose}
                size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Body>
                    {message ?
                        <div className={`alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
                            <strong>{message.msg}</strong>
                        </div>
                        : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        <i className="fa-solid fa-xmark"></i>
                    </Button>
                </Modal.Footer>
            </Modal >
        </>

    )
}

export { Steps };