import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ItemService from '../../../../services/item.service';

//auth
import { useAuth } from '../../../../context/AuthProvider';//

//bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';


//formik
import { Field, useFormik, Formik } from "formik";
import * as Yup from "yup";
import itemService from '../../../../services/item.service';

//validation
const validationSchema = Yup.object().shape({
    item_name: Yup.string()
        .required("Required"),
    item_increase_price: Yup.string()
        .matches(/^\d+(\.\d{2})?$/, 'The price must be a decimal number with two decimal digits (00.00)')
        .required("Required"),
    item_get_data: Yup.string()
        .required("Required"),
    item_type: Yup.string()
        .oneOf(['imageAndTitle', 'select', 'radio'], 'The value must be ImageAndTitle, Select, Radio Button')
        .required("Required"),
    active: Yup.string()
        .oneOf(['1', '0'], 'The value must be YES or NO')
        .required("Required"),

});


function Items() {
    const navigate = useNavigate();
    const { step_id } = useParams();

    //validation auth
    const auth = useAuth();

    //states
    const [newItem, setNewITem] = useState(false);

    //alerts
    const [message, setMessage] = useState(null);

    //images
    const imgUploadBase = require('../../../../assets/img/photo_upload.png')

    //config images
    const [imageItemBase, setImageItemBase] = useState(null);
    const [imageItemBasePlaceholder, setImageItemBasePlaceholder] = useState(null);
    const item_image_ref = useRef(null);
    const item_image_placeholder_ref = useRef(null);

    const [closeCollapse, setCloseCollapse] = useState(false);

    //get items
    const [itemsAll, setItemsAll] = useState([]);

    //search parent item
    const [searchParent, setSearchParent] = useState("");
    const [itemParent, setItemParent] = useState([]);
    const [showOptions, setShowOptions] = useState(false);

    //
    const [parentLoaded, setParentLoaded] = useState(false);

    const [parentName, setPArentName] = useState(null);
    const [parentimage, setParentImage] = useState(null);


    useEffect(() => {
        onGetItem();
        // onGetItemParent();
    }, []);

    //base image url
    const handleImageSave = (event) => {
        const file = event.target.files[0];
        if (file && !/^(image\/jpeg|image\/png)$/.test(file.type)) {
            item_image_ref.current.value = '';
            setMessage({ error: true, msg: 'The file must be of type PNG or JPG' });
            handleShow()
            setImageItemBase('');
            return;
        }
        const maxSize = 1 * 1024 * 1024; // 5 MB
        if (file && file.size > maxSize) {
            item_image_ref.current.value = '';
            setMessage({ error: true, msg: `The file must be less than or equal to  ${maxSize / 1024 / 1024} MB` });
            handleShow()
            setImageItemBase('');
            return;
        }
        setImageItemBase(event.target.files[0]);
    }
    //image item placeholder
    const handleImagePlaceholderSave = (event) => {
        const file = event.target.files[0];
        if (file && !/^(image\/jpeg|image\/png)$/.test(file.type)) {
            item_image_placeholder_ref.current.value = '';
            setImageItemBasePlaceholder('');
            setMessage({ error: true, msg: 'The file must be of type PNG or JPG' });
            handleShow()
            return;
        }
        const maxSize = 1 * 1024 * 1024; // 5 MB
        if (file && file.size > maxSize) {
            item_image_placeholder_ref.current.value = '';
            setImageItemBasePlaceholder('');
            setMessage({ error: true, msg: `The file must be less than or equal to  ${maxSize / 1024 / 1024} MB` });
            handleShow()
            return;
        }
        setImageItemBasePlaceholder(event.target.files[0]);
    }
    //formik
    const formikCreate = useFormik({
        initialValues: {
            item_name: "", item_type: "", item_image: "", item_increase_price: "",
            item_get_data: "", item_image_placeholder: "", active: "", item_parent_id: "", item_is_required: ""
        },
        validateOnBlur: true,
        onSubmit: (values) => {
            onSubmitCreate(values);
        },
        validationSchema: validationSchema,
    });

    //create item BD
    const onGetItem = async () => {
        try {
            const bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                },
            };
            const getITems = await itemService.getItemById(step_id, bearer_Token);
            //console.log(getITems.data)
            if (getITems.status === 200) {
                setItemsAll(getITems.data)
                setShowOptions(true);
            } else {
                setMessage({ error: true, msg: "An error has occurred! Try again." });
            }
        } catch (error) {
            setMessage({ error: true, msg: "An error has occurred! Try again." });
        }
    }
    //get parent items
    const onGetItemParent = (event) => {
        setSearchParent(event.target.value);
        if (event.target.value.length >= 3) {
            searchParentId(event.target.value);
        } else {
            setItemParent([]);
        }
    }
    const searchParentId = async (nombre) => {
        try {
            const bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                },
            };
            const getITemsParent = await itemService.getParentItem(nombre, bearer_Token);
            //console.log(getITemsParent)
            if (getITemsParent.status === 200) {
                setItemParent(getITemsParent.data);
                const select = document.getElementById("my-select");
                select.open = true;
            } else {
                setMessage({ error: true, msg: "An error has occurred! Try again//." });
            }
        } catch (error) {
            setMessage({ error: true, msg: "An error has occurred! Try again/." });
        }
    };

    //get parend info
    const onGetParent = async (id) => {//revisar como activarlo
        try {
            const bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                },
            };
            const getITemsParentInfo = await itemService.getParentInfo(id, bearer_Token);
            if (getITemsParentInfo.status === 200) {
                setPArentName(getITemsParentInfo.data.item_name);
                setParentImage(getITemsParentInfo.data.item_image);
            } else {
                setMessage({ error: true, msg: "An error has occurred! Try again//." });
            }
        } catch (error) {
            setMessage({ error: true, msg: "An error has occurred! Try again/." });
        }
    };

    //create item BD
    const onSubmitCreate = async (values) => {
        const formData = new FormData();
        formData.append('item_image', imageItemBase);
        formData.append('item_image_placeholder', imageItemBasePlaceholder);
        formData.append('item_name', values.item_name);
        formData.append('step_id', step_id);
        formData.append('item_type', values.item_type);
        formData.append('item_increase_price', values.item_increase_price);
        formData.append('item_get_data', values.item_get_data);
        formData.append('item_parent_id', values.item_parent_id);
        formData.append('item_is_required', values.item_is_required);
        formData.append('active', values.active);
        try {
            const bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                },
            };
            //console.log(values.item_parent_id)
            const createITem = await itemService.createItem(step_id, formData, bearer_Token);
            // console.log(createITem)
            if (createITem.status === 200 && createITem.data.statusCode === 200) {
                setMessage({ error: false, msg: "Item Create susscesful." });
                handleClearCreate();
                onGetItem();
                handleShow();
            } else {
                setMessage({ error: true, msg: "An error has occurred! Try again." });
                handleShow();
            }
        } catch (error) {
            setMessage({ error: true, msg: "An error has occurred! Try again." });
            handleShow();
        }
    };

    //update item bd
    const onSubmitUpdate = async (values) => {

        let baseimg = '';
        let baseimgPlaceholder = '';

        if (imageItemBase) {
            baseimg = imageItemBase;
        } else {
            baseimg = values.item_image;
        }
        if (imageItemBasePlaceholder) {
            baseimgPlaceholder = imageItemBasePlaceholder;
        } else {
            baseimgPlaceholder = values.item_image_placeholder;
        }
        const formData = new FormData();
        formData.append('item_image', baseimg);
        formData.append('item_image_placeholder', baseimgPlaceholder);
        formData.append('item_name', values.item_name);
        formData.append('step_id', step_id);
        formData.append('item_type', values.item_type);
        formData.append('item_increase_price', values.item_increase_price);
        formData.append('item_get_data', values.item_get_data);
        formData.append('item_parent_id', values.item_parent_id);
        formData.append('item_is_required', values.item_is_required);
        formData.append('active', values.active);
        //console.log(formData)
        try {
            const bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                },
            };
            const updateItem = await itemService.UpdateItemById(values.id, formData, bearer_Token);
            if (updateItem.status === 200 && updateItem.data.statusCode === 200) {
                setMessage({ error: false, msg: "Item Updated susscesful." });
                onGetItem();
                handleShow();
            } else {
                setMessage({ error: true, msg: "An error has occurred! Try again." });
                handleShow();
            }
        } catch (error) {
            setMessage({ error: true, msg: "An error has occurred! Try again." });
            handleShow();
        }
    }

    //delete item
    const onDeleteItemByid = async (id) => {
        try {
            const bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                },
            };
            const deleteItem = await itemService.deleteItem(id, bearer_Token);
            //console.log(deleteItem)
            if (deleteItem.status === 200 && deleteItem.data.statusCode === 200) {
                setMessage({ error: false, msg: "Successfully deleted" });
                onGetItem();
                handleShow();
            } else {
                setMessage({ error: true, msg: "An error has occurred! Try againsss." });
                handleShow();
            }
        } catch (error) {
            setMessage({ error: true, msg: "An error has occurred! Try againnnnnn." });
            handleShow();
        }
    }

    //clear form an fiules
    const handleClearCreate = () => {
        setNewITem(false);
        formikCreate.resetForm()
        setImageItemBase('');
        setImageItemBasePlaceholder('');
        item_image_placeholder_ref.current.value = '';
        item_image_ref.current.value = '';
    };

    //close collapse
    const handleCloseCollapse = () => {
        setCloseCollapse(false)
    }
    const handleActiveCollapse = () => {
        setCloseCollapse(false)
    }

    //modal config
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsAll.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(itemsAll.length / itemsPerPage);
    const handleClick = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };
    const prevButtonDisabled = currentPage === 1;
    const nextButtonDisabled = currentPage === Math.ceil(itemsAll.length / itemsPerPage);
    //emd pagination

    //search
    const [searchTerm, setSearchTerm] = useState('');
    const filteredProducts = currentItems.filter((item) =>
        item.item_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    //back button
    const goBack = () => {
        navigate(-1);
    }
    return (
        <>
            <div className="container-fluid">
                <button type="button" onClick={goBack} className="btn btn-outline-secondary  btn-sm mb-4">
                    <i className="fa-solid fa-chevron-left"></i>Back
                </button>
                <h1 className="h3 mb-4 text-gray-800">Items By Step</h1>
                <div className="my-2"></div>

                <button onClick={() => setNewITem(true)} className="btn btn-info btn-icon-split mb-4">
                    <span className="icon text-white-50">
                        <i className="fa-solid fa-plus"></i>
                    </span>
                    <span className="text">Add new item</span>
                </button>
                {/*  create item */}
                <div className='row'>
                    {newItem
                        ?
                        <div className="card mb-4 py-3 border-left-info">
                            <div className="card-body">
                                <form onSubmit={formikCreate.handleSubmit} >
                                    <div className="d-flex justify-content-end">
                                        <button onClick={handleClearCreate} className="btn btn-danger btn-icon-split mb-4 ">
                                            <span className="icon text-white-50">
                                                <i className="fa-solid fa-circle-xmark"></i>
                                            </span>
                                            <span className="text">Close</span>
                                        </button>
                                    </div>
                                    <div className='row'>

                                        <input type="hidden" disabled className="form-control" id="step_id" name='step_id'
                                            value={step_id} />
                                        <div className='row mb-3'>
                                            <div className='col-lg-3  col-md-12 col-sm-12 col-xs-12'>
                                                <img src={imageItemBase ? URL.createObjectURL(imageItemBase) : imgUploadBase} className="w-25 avatar img-circle img-thumbnail" alt="item" />
                                                <div className="form-text">Image Item.</div>
                                            </div>

                                            <div className='col-lg-9  col-md-12 col-sm-12 col-xs-12'>
                                                <input ref={item_image_ref} id='item_image' name='item_image' onChange={handleImageSave} className="form-control form-control-sm mt-3" type="file"
                                                />
                                            </div>
                                            {formikCreate.errors.item_image && formikCreate.touched.item_image && (
                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.item_image}</div>
                                            )}
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col-lg-3  col-md-12 col-sm-12 col-xs-12'>
                                                <img src={imageItemBasePlaceholder ? URL.createObjectURL(imageItemBasePlaceholder) : imgUploadBase} className="w-25 avatar img-circle img-thumbnail" alt="item" />
                                                <div className="form-text">Image Item Placeholder.</div>
                                            </div>
                                            <div className='col-lg-9  col-md-12 col-sm-12 col-xs-12'>
                                                <input ref={item_image_placeholder_ref} id='item_image_placeholder' name='item_image_placeholder' onChange={handleImagePlaceholderSave} className="form-control form-control-sm mt-3" type="file"
                                                />
                                            </div>
                                        </div>
                                        <div className="input-group mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <label className="input-group-text">Name</label>
                                            <input type="text" className="form-control" id='item_name' name='item_name'
                                                value={formikCreate.values.item_name}
                                                onChange={formikCreate.handleChange}
                                                onBlur={formikCreate.handleBlur} />
                                            {formikCreate.errors.item_name && formikCreate.touched.item_name && (
                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.item_name}</div>
                                            )}
                                        </div>

                                        <div className="input-group mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <label className="input-group-text" >Item Options</label>
                                            <select className="form-select" id='item_type' name='item_type'
                                                value={formikCreate.values.item_type}
                                                onChange={formikCreate.handleChange}
                                                onBlur={formikCreate.handleBlur} >
                                                <option value="">Choose...</option>
                                                <option value="imageAndTitle">Title and Image</option>
                                                <option value="select">Select</option>
                                                <option value="radio">Radio Button</option>
                                            </select>
                                            {formikCreate.errors.item_type && formikCreate.touched.item_type && (
                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.item_type}</div>
                                            )}
                                        </div>

                                        <div className="mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <div className="input-group">
                                                <span className="input-group-text" >Item Increase $0.00 </span>
                                                <input type="number" className="form-control" id='item_increase_price' name='item_increase_price'
                                                    value={formikCreate.values.item_increase_price}
                                                    onChange={formikCreate.handleChange}
                                                    onBlur={formikCreate.handleBlur} />
                                                {formikCreate.errors.item_increase_price && formikCreate.touched.item_increase_price && (
                                                    <div className="form-text login-create-help text-danger">{formikCreate.errors.item_increase_price}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon3">Quantity</span>
                                                <input type="number" className="form-control" id='item_get_data' name='item_get_data'
                                                    value={formikCreate.values.item_get_data}
                                                    onChange={formikCreate.handleChange}
                                                    onBlur={formikCreate.handleBlur} />
                                                {formikCreate.errors.item_get_data && formikCreate.touched.item_get_data && (
                                                    <div className="form-text login-create-help text-danger">{formikCreate.errors.item_get_data}</div>
                                                )}
                                            </div>
                                        </div>


                                        <div className="input-group mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <label className="input-group-text" >Item Required?</label>
                                            <select className="form-select" id='item_is_required' name='item_is_required'
                                                value={formikCreate.values.item_is_required}
                                                onChange={formikCreate.handleChange}
                                                onBlur={formikCreate.handleBlur} >
                                                <option value="">Choose...</option>
                                                <option value="1">YES</option>
                                                <option value="0">NO</option>
                                            </select>
                                            {formikCreate.errors.item_is_required && formikCreate.touched.item_is_required && (
                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.item_is_required}</div>
                                            )}
                                        </div>

                                        <div className="input-group mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <label className="input-group-text" >Active</label>
                                            <select className="form-select" id='active' name='active'
                                                value={formikCreate.values.active}
                                                onChange={formikCreate.handleChange}
                                                onBlur={formikCreate.handleBlur} >
                                                <option value="">Choose...</option>
                                                <option value="1">YES</option>
                                                <option value="0">NO</option>
                                            </select>
                                            {formikCreate.errors.active && formikCreate.touched.active && (
                                                <div className="form-text login-create-help text-danger">{formikCreate.errors.active}</div>
                                            )}
                                        </div>

                                        <div className='col-lg-7  col-md-12 col-sm-12 col-xs-12'>
                                            <div className="form-floating mb-3">
                                                <input type="text" value={searchParent} onChange={onGetItemParent} className="form-control" id="parent_item" placeholder="" />
                                                <label ><i className="fa-solid fa-magnifying-glass"></i> search item parent</label>
                                            </div>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                size={showOptions ? itemParent.length : 1}
                                                id='item_parent_id' name='item_parent_id'
                                                value={formikCreate.values.item_parent_id}
                                                onChange={formikCreate.handleChange}
                                                onBlur={formikCreate.handleBlur} >
                                                {itemParent.map((usuario, i) => (
                                                    <option key={i} value={usuario.id}>
                                                        {usuario.item_name} <img className='' style={{ width: '30px' }} src={usuario.item_image} />
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                    </div>
                                    <button type='submit' disabled={!formikCreate.isValid} className="mt-5 btn btn-info btn-icon-split mb-4">
                                        <span className="icon text-white-50">
                                            <i className="fa-solid fa-floppy-disk"></i>
                                        </span>
                                        <span className="text">Save</span>
                                    </button>
                                </form>
                            </div>
                        </div>
                        : <></>
                    }
                </div>
                {/* end create item */}

                {/* update item */}
                <div className='col-lg-3 col-mb-12 col-sm-12 col-xs-12'>
                    <form>
                        <div className="form-floating mb-3">
                            <input type="text" value={searchTerm} onChange={handleSearch} className="form-control" id="floatingInput" placeholder="" />
                            <label ><i className="fa-solid fa-magnifying-glass"></i> search</label>
                        </div>
                    </form>
                </div>
                <div className="accordion accordion-flush  " id="accordionFlushExample">
                    {filteredProducts.map((item, i) => {
                        return (
                            <Accordion className="" key={item.id} onClick={() => onGetParent(item.item_parent_id)}>
                                <Accordion.Item className="" eventKey={i} >
                                    <Accordion.Header className="mb-3 ">
                                        <div className="p-1 mb-2 ">
                                            <p><i className="fa-regular fa-folder"></i> <b>{item.item_name}</b></p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='row'>
                                            <div className="card mb-4 py-3 border-left-info">
                                                <div className="card-body">
                                                    <Formik
                                                        initialValues={{
                                                            id: item.id ? item.id : '',
                                                            item_name: item.item_name ? item.item_name : '',
                                                            item_type: item.item_type ? item.item_type : '',
                                                            item_image: item.item_image ? item.item_image : '',
                                                            item_increase_price: item.item_increase_price ? item.item_increase_price : '',
                                                            item_get_data: item.item_get_data ? item.item_get_data : '',
                                                            item_image_placeholder: item.item_image_placeholder ? item.item_image_placeholder : '',
                                                            active: item.active === 1 ? item.active : '0',
                                                            item_parent_id: item.item_parent_id ? item.item_parent_id : '',
                                                            item_is_required: item.item_is_required ? item.item_is_required : '0'
                                                        }}
                                                        validateOnBlur={true}
                                                        onSubmit={(values) => {
                                                            onSubmitUpdate(values);
                                                        }}
                                                        validationSchema={validationSchema} >
                                                        {formikUpdate => (
                                                            <form onSubmit={formikUpdate.handleSubmit} >
                                                                <div className="d-flex justify-content-end">
                                                                    <button type='button' onClick={() => onDeleteItemByid(item.id)} className="btn btn-danger btn-icon-split mb-4 " >
                                                                        <span className="icon text-white-50">
                                                                            <i className="fa-regular fa-trash-can"></i>
                                                                        </span>
                                                                        <span className="text">Delete</span>
                                                                    </button>
                                                                </div>
                                                                <div className='row'>
                                                                    <input type="hidden" disabled className="form-control" id="step_id" name='step_id'
                                                                        value={step_id} />
                                                                    <div className='row mb-3'>
                                                                        <div className='col-lg-3  col-md-12 col-sm-12 col-xs-12'>
                                                                            <img src={item.item_image ? item.item_image : imgUploadBase} className="w-25 avatar img-circle img-thumbnail" alt="item" />
                                                                            <div className="form-text">Image Item.</div>
                                                                        </div>

                                                                        <div className='col-lg-9  col-md-12 col-sm-12 col-xs-12'>
                                                                            <input ref={item_image_ref} id='item_image' name='item_image' onChange={handleImageSave} className="form-control form-control-sm mt-3" type="file"
                                                                            />
                                                                        </div>
                                                                        {formikUpdate.errors.item_image && formikUpdate.touched.item_image && (
                                                                            <div className="form-text login-create-help text-danger">{formikUpdate.errors.item_image}</div>
                                                                        )}
                                                                    </div>
                                                                    <div className='row mb-3'>
                                                                        <div className='col-lg-3  col-md-12 col-sm-12 col-xs-12'>
                                                                            <img src={item.item_image_placeholder ? item.item_image_placeholder : imgUploadBase} className="w-25 avatar img-circle img-thumbnail" alt="item" />
                                                                            <div className="form-text">Image Item Placeholder.</div>
                                                                        </div>
                                                                        <div className='col-lg-9  col-md-12 col-sm-12 col-xs-12'>
                                                                            <input ref={item_image_placeholder_ref} id='item_image_placeholder' name='item_image_placeholder' onChange={handleImagePlaceholderSave} className="form-control form-control-sm mt-3" type="file"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-group mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                        <label className="input-group-text">Name</label>
                                                                        <input type="text" className="form-control" id='item_name' name='item_name'
                                                                            value={formikUpdate.values.item_name}
                                                                            onChange={formikUpdate.handleChange}
                                                                            onBlur={formikUpdate.handleBlur} />
                                                                        {formikUpdate.errors.item_name && formikUpdate.touched.item_name && (
                                                                            <div className="form-text login-create-help text-danger">{formikUpdate.errors.item_name}</div>
                                                                        )}
                                                                    </div>

                                                                    <div className="input-group mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                        <label className="input-group-text" >Item Options</label>
                                                                        <select className="form-select" id='item_type' name='item_type'
                                                                            value={formikUpdate.values.item_type}
                                                                            onChange={formikUpdate.handleChange}
                                                                            onBlur={formikUpdate.handleBlur} >
                                                                            <option value="">Choose...</option>
                                                                            <option value="imageAndTitle">Title and Image</option>
                                                                            <option value="select">Select</option>
                                                                            <option value="radio">Radio Button</option>
                                                                        </select>
                                                                        {formikUpdate.errors.item_type && formikUpdate.touched.item_type && (
                                                                            <div className="form-text login-create-help text-danger">{formikUpdate.errors.item_type}</div>
                                                                        )}
                                                                    </div>

                                                                    <div className="mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                        <div className="input-group">
                                                                            <span className="input-group-text" >Item Increase $0.00 </span>
                                                                            <input type="number" className="form-control" id='item_increase_price' name='item_increase_price'
                                                                                value={formikUpdate.values.item_increase_price}
                                                                                onChange={formikUpdate.handleChange}
                                                                                onBlur={formikUpdate.handleBlur} />
                                                                            {formikUpdate.errors.item_increase_price && formikUpdate.touched.item_increase_price && (
                                                                                <div className="form-text login-create-help text-danger">{formikUpdate.errors.item_increase_price}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                        <div className="input-group">
                                                                            <span className="input-group-text" id="basic-addon3">Quantity</span>
                                                                            <input type="number" className="form-control" id='item_get_data' name='item_get_data'
                                                                                value={formikUpdate.values.item_get_data}
                                                                                onChange={formikUpdate.handleChange}
                                                                                onBlur={formikUpdate.handleBlur} />
                                                                            {formikUpdate.errors.item_get_data && formikUpdate.touched.item_get_data && (
                                                                                <div className="form-text login-create-help text-danger">{formikUpdate.errors.item_get_data}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-group mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                        <label className="input-group-text" >Item Required?</label>
                                                                        <select className="form-select" id='item_is_required' name='item_is_required'
                                                                            value={formikUpdate.values.item_is_required}
                                                                            onChange={formikUpdate.handleChange}
                                                                            onBlur={formikUpdate.handleBlur} >
                                                                            <option value="">Choose...</option>
                                                                            <option value="1">YES</option>
                                                                            <option value="0">NO</option>
                                                                        </select>
                                                                        {formikUpdate.errors.item_is_required && formikUpdate.touched.item_is_required && (
                                                                            <div className="form-text login-create-help text-danger">{formikUpdate.errors.item_is_required}</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="input-group mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                        <label className="input-group-text" >Active</label>
                                                                        <select className="form-select" id='active' name='active'
                                                                            value={formikUpdate.values.active}
                                                                            onChange={formikUpdate.handleChange}
                                                                            onBlur={formikUpdate.handleBlur} >
                                                                            <option value="">Choose...</option>
                                                                            <option value="1">YES</option>
                                                                            <option value="0">NO</option>
                                                                        </select>
                                                                        {formikUpdate.errors.active && formikUpdate.touched.active && (
                                                                            <div className="form-text login-create-help text-danger">{formikUpdate.errors.active}</div>
                                                                        )}
                                                                    </div>
                                                                    <div className='col-lg-7  col-md-12 col-sm-12 col-xs-12'>



                                                                        {item.item_parent_id
                                                                            ? <div><p className="badge bg-info text-wrap" >{parentName}</p>
                                                                                <img className='' style={{ width: '30px' }} src={parentimage} />
                                                                            </div>
                                                                            : <p className="badge bg-warning text-wrap" >No parent</p>
                                                                        }

                                                                        <div className="form-floating mb-3">
                                                                            <input type="text" value={searchParent} onChange={onGetItemParent} className="form-control" id="parent_item" placeholder="" />
                                                                            <label ><i className="fa-solid fa-magnifying-glass"></i> search item parent</label>
                                                                        </div>
                                                                        <select
                                                                            className="form-select"
                                                                            aria-label="Default select example"
                                                                            size={showOptions ? itemParent.length : 1}
                                                                            id='item_parent_id' name='item_parent_id'
                                                                            value={formikUpdate.values.item_parent_id}
                                                                            onChange={formikUpdate.handleChange}
                                                                            onBlur={formikUpdate.handleBlur} >
                                                                            {itemParent.map((usuario, i) => (
                                                                                <option key={i} value={usuario.id}>
                                                                                    {usuario.item_name} <img className='' style={{ width: '30px' }} src={usuario.item_image} />
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <button type='submit' disabled={!formikUpdate.isValid} className="btn btn-info btn-icon-split mb-4 mt-4">
                                                                    <span className="icon text-white-50">
                                                                        <i className="fa-solid fa-floppy-disk"></i>
                                                                    </span>
                                                                    <span className="text">Update</span>
                                                                </button>
                                                            </form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        )
                    })}
                </div>
                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        <li className={prevButtonDisabled ? 'disabled page-item' : 'page-item'} ><a onClick={handlePrevClick} className="page-link">Previous</a></li>
                        {pageNumbers.map(number => (
                            <li className="page-item" key={number}>
                                <a className="page-link" href="#" onClick={e => handleClick(e, number)}>
                                    {number}
                                </a>
                            </li>
                        ))}
                        <li className={nextButtonDisabled ? 'disabled page-item' : 'page-item'}><a onClick={handleNextClick} className="page-link">Next</a></li>
                    </ul>
                </nav>

                {/* end update item */}
            </div>

            {/* modal */}
            < Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} onClick={handleClose}
                size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Body>
                    {message ?
                        <div className={`alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
                            <strong>{message.msg}</strong>
                        </div>
                        : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        <i className="fa-solid fa-xmark"></i>
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    )
} export { Items };

