import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class AuthService {

    login(data){
        return http.post("/auth", data).then(response => {
            return response
        })
    }
}
export default new AuthService();