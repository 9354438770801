import http from "../configs/http.config";

class ShadeService {

  getAll() {
    return http.get("/products");
  }

  getProductDetail(product_id) {
    return http.get(`/products/${product_id}`);
  }

  UpdateProduct(product_id, data) {
    return http.patch(`/products/${product_id}`, data);
  }
  createProduct(data, authorization) {
    return http.post(`/products`, data, authorization);
  }

  deleteProduct(product_id, authorization) {
    return http.delete(`/products/${product_id}`, authorization);
  }

  doGetDynamicRequest(url) {
    return http.get(`${url}`);
  }
  //update image url
  updateImgUrl(product_id, data, authorization) {
    return http.post(`/products/image_url/${product_id}`, data, authorization);
  }
  //update  background image url
  updateBacgroundImgUrl(product_id, data, authorization) {
    return http.post(`/products/background_image_url/${product_id}`, data, authorization);
  }
  //update  base image url
  updateBaseImgUrl(product_id, data, authorization) {
    return http.post(`/products/base_image_url/${product_id}`, data, authorization);
  }

}

export default new ShadeService();