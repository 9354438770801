import react from 'react';

import { HashRouter, Route, Routes } from 'react-router-dom'

import { TodoMenu } from './components/layouts/Menu'
import { Login } from './components/pages/login/Login'
import { Unauthorized } from './components/layouts/Unauthorized'

import { AuthProvider,AuthRoute,PrivateRoute } from './context/AuthProvider.js'


function App() {

    return (
      <HashRouter>
        <AuthProvider>
          <div id="wrapper">
            <Routes>
            <Route path='/login' element={<Login />} > </Route>
            <Route path='/unauthorized' element={<Unauthorized />} > </Route>
            <Route path='/*' element={<AuthRoute><TodoMenu /></AuthRoute>} > </Route>
            </Routes>
          </div>
        </AuthProvider>
      </HashRouter>
    );
  }

//   return (
//     <HashRouter>
//       <div id="wrapper">
//         <Routes>
//           <Route path='/login' element={<Login />} > </Route>
//           <Route path='/*' element={<TodoMenu />} > </Route>
//         </Routes>
//       </div>

//     </HashRouter>
//   );
// }

export default App;
