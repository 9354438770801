import React from "react";
import { useAuth } from '../../context/AuthProvider.js'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';



function ProfileNav() {

    const auth = useAuth();
    const shoot = () => {
        auth.logout();
    }

    return (
        <>
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"></div>

                    <li>
                        <NavDropdown title={<i className="fa-regular fa-user"></i>} id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Profile</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item  onClick={shoot}>
                            <i className="fa-solid fa-arrow-right-from-bracket" ></i> Log Out
                            </NavDropdown.Item>
                        </NavDropdown>
                    </li>



                </ul>
            </nav>
        </>

    );
}
export { ProfileNav };
